import React from 'react'
import ResetPassword from '../features/auth/components/ResetPassword'

const ResetPasswordPage = () => {
  return (
    <div>
        <ResetPassword></ResetPassword>
    </div>
  )
}

export default ResetPasswordPage