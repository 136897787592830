import React from 'react'
import ForgotPassword from '../features/auth/components/ForgotPassword'

const ForgotPasswordPage = () => {
  return (
    <div>
        <ForgotPassword></ForgotPassword>
    </div>
  )
}

export default ForgotPasswordPage