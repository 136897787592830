import React from 'react'
import Signup from '../features/auth/components/Signup'

const SignUp = () => {
  return (
    <div>
      <Signup></Signup>
    </div>
  )
}

export default SignUp