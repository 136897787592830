export function addToCart(item) {
  return new Promise(async (resolve) => {
    // kind of uploading data on our api
    const response = await fetch("/cart", {
      method: "POST",
      body: JSON.stringify(item),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}

export function updateCart(update) {
  return new Promise(async (resolve) => {
    // kind of uploading data on our api
    const response = await fetch("/cart/" + update.id, {
      method: "PATCH",
      body: JSON.stringify(update),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data });
  });
}

export function deleteCartItem(itemId) {
  return new Promise(async (resolve) => {
    const response = await fetch("/cart/" + itemId, {
      method: "DELETE",
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    resolve({ data: { id: itemId } });
  });
}


export function fetchItemsByUserId() {
  return new Promise(async (resolve) => {

    const response = await fetch("/cart" );
    const data = await response.json();
    resolve({ data });
  });
}

export function resetCart() {
  return new Promise(async (resolve) => {

    const response = await fetchItemsByUserId();
    const items = response.data;

    for (let item of items) {
      await deleteCartItem(item.id)
    }
    resolve({ status: 'success' });
  });
}